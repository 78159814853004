import React from 'react'

export default function WasThisPageHelpfull() {
    return (
        <div className='container-Feed  text-center d-flex justify-content-center my-5 mx-2 m-auto'>
            <p className='pt-3'>
                <span className='px-2'>Was this page help full ?</span> <i className="bi bi-hand-thumbs-down fs-5"></i> <i className="bi bi-hand-thumbs-up fs-5 mx-1"></i>
            </p>
        </div>
    )
}
